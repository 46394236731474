.maintainanceContainer {
    width: 90vw;
    height: auto;
    text-align: left;
    margin: 0 auto;
    /* font-size: 20px; */
    line-height: 2rem;
}

.maintainanceContent h5 {
    padding: 1rem;
}

.maintenanceListItem {
    font-size: 20px;
    font-weight: 400;
}