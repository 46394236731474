.footerContainer {
  width: 100%;

  padding-bottom: 100px;
  margin: 0 auto;
  border: 1px solid #fff;
  background-image: url("./images/team1.png");
  background-size: cover;
  object-fit: cover;
  background-position: bottom center;

  /*  background-image: linear-gradient( #180830 10%, #38022A 60%);*/
  color: #fff;
}

.logoFooter {
  width: 70px;
  height: 70px;
  position: relative;
  margin-left: 25px;
  margin-bottom: 1rem;
  box-shadow: 15px 15px 5px #333 inset;
}

.logoFooter img {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 2px;
}

.footerLinks {
  display: flex;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  gap: 0.8rem;
  color: #fff;
  font-weight: 700;
  text-align: left;
}

.footerLinksleft {
  width: 20%;
  height: auto;
  line-height: 20px;
  text-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 15px;
  border-radius: 20px;
}
.footerLinksleft ul li a {
  color: #fff;
}
.footerLinksCenter ul li a {
  color: #fff;
}

.footerLinksCenter {
  width: 25%;
  height: auto;
  line-height: 20px;
  text-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 15px;
  border-radius: 20px;
  vertical-align: middle;
}

.footerLinksRight {
  width: 25%;
  height: auto;
  line-height: 25px;
  text-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 15px;
  border-radius: 20px;
}

.footerLinksRight h2 {
  color: yellow;
}

.overseasAddress h2 {
  color: yellow;
}

.overseasAddress {
  font-size: large;
  width: 25%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 15px;
  border-radius: 20px;
  inset: 1px;
}

.footerLinks {
  font-size: large;
}

.overseasAddress h6 {
  font-weight: 500;
  line-height: 20px;
}

.footer {
  width: 100vw;
  height: 5rem;
  text-align: center;
  align-items: center;
  /*  background-image: linear-gradient( #180830 10%, #38022A 60%);*/
}

.footer img {
  display: none;
}

.footer p {
  font-weight: 800;
  padding-top: 1.3rem;
  color: var(--text-clr);
}

@media (max-width: 600px) {
  .footerContainer {
    display: none;
  }

  .footer img {
    width: 3rem;
    display: inline-block;
  }
}

@media (972px <=width <=1400px) {
  .footerLinks {
    font-size: 1rem;
  }
}
