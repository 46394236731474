.refinaryContainer {
  width: 100%;
  height: 100%;
  font-size: 40px;
  justify-content: center;
  overflow-y: hidden;
  text-align: justify;
  overflow: hidden;
}

.refinary {
  width: 92vw;
  height: auto;

  display: flex;
}

.HimgContainer {
  width: 92vw;
  height: 70vh;
  margin: 0 auto;
  background-color: transparent;
}

.RefinieryHeaderImage {
  width: 92vw;
  height: 70vh;
  box-shadow: 10px 10px 10px -10px rgb(252, 252, 252);
}

.Refinery {
  font-size: 2.5rem;
  font-weight: 900;
  text-align: right;
  padding-right: 6rem;
  align-items: center;
  margin-right: 0%, auto;
  /* margin-bottom: 5rem; */
  padding-top: 10px;
  background: linear-gradient(
    145deg,
    yellow,
    rgb(253, 176, 176),
    rgb(255, 114, 114),
    rgb(203, 203, 37)
  );
  -webkit-background-clip: text;
  background-size: 300% 300%;
  -webkit-text-fill-color: transparent;
  animation: gradient 10s ease-in-out infinite;
}

.pageHeading {
  width: 90%;
  text-align: center;
  text-transform: uppercase;
  background-color: rgba(214, 91, 91);
  padding-top: 10px;
  border-radius: 1em;
  position: relative;
}

.refinary {
  width: 90%;
  text-align: center;
  text-transform: uppercase;
  background-color: rgba(214, 91, 91);
  border-radius: 2em;
}

.pageHeading2 {
  width: 90%;
  text-align: center;
  text-transform: uppercase;
  background-color: rgba(214, 91, 91);
  margin: 1em auto;
  border-radius: 0.3em;
}
article {
  font-size: 1.4rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 1rem;
  box-shadow: 10px 10px 10px #c3c3c3;
  line-height: 3rem;
}

.refinery1 {
  width: 80%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7rem;
  margin: 3rem auto;
}

.refinery1 h5 {
  height: auto;
  line-height: 2em;
  text-align: justify;
  /* margin-top: 6em; */
  padding: 1em;
  font-size: 1.2rem;
}

.refImg {
  width: 39.75rem;
  height: 26rem;
  border: 2px solid rgba(214, 91, 91);
  box-shadow: -10px -10px 5px rgba(214, 91, 91);
  z-index: 10;
}

.ref2 {
  width: 90%;
  height: auto;
  display: flex;
  text-align: left;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px;
}

.ref2 h5 {
  line-height: 2em;
}

.reffImg2 {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.reffImg2 Img {
  width: 18em;
  height: 11em;
  box-shadow: 10px -10px 5px rgba(214, 91, 91);
}

.mecpro1 {
  width: 90%;
  height: auto;
  line-height: 2em;
  text-align: justify;
  font-size: 1.4rem;

  margin: 0 auto;
}

.refVid {
  width: 80%;
  height: 70vh;
  /*  margin: 5% auto; */
  object-fit: contain;
  background-color: #000;
  margin: 1rem auto;
  box-shadow: 15px 15px 10px rgb(13, 13, 13);
  border: 10px solid black;
  border-radius: 20px;
}

.refVid video {
  height: 70vh;
  aspect-ratio: 12/5;
}

.Ref3 {
  width: 90%;
  height: auto;
  margin: 3em auto;
  font-size: 20px;
  font-weight: 500;
}

ol {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  font-size: 1.4rem;
}

ol ul {
  list-style-type: disc;
}

ol ul li {
  font-size: 1.4rem;
}

.MecOffer {
  color: rgba(214, 91, 91);
  font-size: 1.5rem;
  text-align: left;
  font-weight: 800;
}

ul li::before {
  content: "•";
  font-size: 1.5em;
  margin-right: 0.3em;
  color: rgba(214, 91, 91);
}

@media (max-width: 600px) {
  .refinery1 {
    flex-direction: column;
    width: 100vw;
    height: auto;
    display: flex;
    font-size: 10px;
    justify-content: center;
    align-items: center;

    padding-bottom: 2rem;
    /*  margin-top: 7em; */
    gap: 1em;
  }

  .Refinery {
    text-align: center;
    font-size: 1rem;
    padding-right: 0;
    margin-top: 2rem;
  }

  .refinery1 > h1 {
    height: 1em;
    line-height: 2em;
    text-align: justify;

    padding: 0;
  }

  article {
    font-size: 1.4rem;
  }

  .containerHead {
    height: 60px;
  }

  .Logo {
    width: 50px;
    height: 60px;
    margin-left: 5px;
    margin-top: -2px;
  }

  .siteHeading {
    padding: 0;
    height: 60px;
  }

  .siteHeading1 {
    font-size: 2em;
    margin-bottom: 1px;
  }

  .siteHeading2 {
    font-size: 1.1em;
  }

  .HimgContainer {
    width: 90vw;
    height: 25vh;
    margin-left: 1.4em;
    margin-top: 1.4em;
  }

  .RefinieryHeaderImage {
    width: 100%;
    height: 100%;
  }

  .refImg {
    width: 30em;
    height: 20em;
    box-shadow: 5px -5px 5px rgba(214, 91, 91);
    margin-left: 4.8em;
    margin-top: 4.8rem;
  }

  .refinery1 h5 {
    margin: 0;
    width: 20em;
    line-height: 1.5em;
    margin-left: 3em;
  }

  .ref1h5 {
    font-size: 15px;
    margin: 0;
    text-align: justify;
    width: 30em;
    height: 60em;
    line-height: 1.5em;
  }

  .refinaryContainer {
    width: 100vw;
    height: auto;
    font-size: 20px;
    overflow-y: hidden;
  }

  .pageHeading {
    width: 41vw;

    margin-left: 30%;
    margin-top: 20px;
    margin-bottom: 1em;
  }

  .ref2 {
    flex-direction: column-reverse;
    font-size: 20px;
    margin: o auto;
  }

  .reffImg2 {
    width: 30em;
    height: 25em;
  }

  .pageHeading2 {
    width: 90vw;
    background-color: rgba(214, 91, 91);
    border-radius: 20px;
    margin: 8rem auto 4rem auto;
    text-align: center;
  }

  .mecpro1 {
    font-size: 15px;
    margin: 1.5em auto;
    line-height: 1.5em;
    padding-bottom: 4rem;
  }

  .refVid {
    width: 100vw;
    height: 40vh;
    object-fit: cover;
    display: flex;
    align-items: center;
  }

  .refVid video {
    width: 100vw;
  }

  ol ul li {
    font-size: 1.2rem;
  }

  ul {
    padding-left: 0;
  }
}
