.patcontainer {
  width: 100vw;
  height: auto;
  margin: 5rem auto 0 auto;
  padding-bottom: 10px;
  overflow: hidden;
}

.patent {
  width: 13rem;
  height: 15rem;
}

.patentContainer {
  width: 100%;
  height: auto;
  margin: 5% auto;
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 2rem;
}

.boxtop {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;
  margin: 0 auto;
}

.boxbottom {
  display: flex;
  gap: 8rem;
  align-items: center;
  justify-content: center;
}

.pvidContainer {
  width: 80%;
  height: auto;
  margin: 5% auto;
  object-fit: cover;
  background-color: #000;
  /* margin: 0 auto; */
  box-shadow: 15px 15px 10px rgb(13, 13, 13);
  border: 10px solid black;
  border-radius: 20px;

  position: relative;
}

.pvidContainer > video {
  width: 90%;
  height: auto;
}

.patentHeading {
  border-bottom: var(--border-bottom);
  color: var(--text-clr);
  text-shadow: var(--text-shadow);
  width: 90%;
  margin-left: 5rem;
  margin-top: 5%;
}

@media (max-width: 600px) {
  .patent {
    width: 6rem;
    height: 9rem;
  }

  .patcontainer {
    height: auto;
    margin: 0 auto;
    padding-bottom: 10px;
    overflow: hidden;
    overflow-x: hidden;
  }

  .patentContainer {
    height: auto;
    margin: 0 auto;
    color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .boxtop {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 0 auto;
    flex-wrap: wrap;
  }

  .boxbottom {
    display: flex;

    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: -22px;
  }

  .pvidContainer {
    width: 80%;
    height: auto;
    margin: 5% auto;
    object-fit: contain;
    background-color: #000;
    margin: 1rem auto;
    box-shadow: 15px 15px 10px rgb(13, 13, 13);
    border: 10px solid black;
    border-radius: 20px;
  }
}

@media (max-width: 768px) {
  .patent {
    width: 6rem;
    height: 9rem;
  }

  .patcontainer {
    height: auto;
    margin: 0 auto;
    padding-bottom: 10px;
    overflow: hidden;
    overflow-x: hidden;
  }

  .patentContainer {
    height: auto;
    margin: 0 auto;
    color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .boxtop {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 0 auto;
    flex-wrap: wrap;
  }

  .boxbottom {
    display: flex;

    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: -22px;
  }

  .pvidContainer {
    width: 80%;
    height: auto;
    margin: 5% auto;
    object-fit: contain;
    background-color: #000;
    margin: 1rem auto;
    box-shadow: 15px 15px 10px rgb(13, 13, 13);
    border: 10px solid black;
    border-radius: 20px;
  }
}
