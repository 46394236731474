.videoContainer {
    width: 90vw;
    height: auto;
    margin-inline: auto;
    margin-top: 5rem;





}



.vid {
    width: 25rem;
    height: auto;
    border: 2px solid(255, 255, 255);


}

.videoHeading>h1 {
    border-bottom: var(--border-bottom);
    color: var(--text-clr);
    text-shadow: var(--text-shadow);

    width: 100%;
    margin-left: -10px;
    margin-top: 10%;



}

.PatentVid>h1 {
    border-bottom: var(--border-bottom);
    color: var(--text-clr);
    text-shadow: var(--text-shadow);
    width: 90%;
    margin: 0 auto;
    padding-top: 50px;

}

@media (max-width:600px) {
    .vid {
        width: 50rem;
        height: auto;
        border: 2px solid(255, 255, 255);
        /* margin-inline: 1rem; */

    }

    @media (max-width:768px) {

        .vid {
            width: 21rem;
            height: auto;
            border: 2px solid(255, 255, 255);
            /* margin-inline: 1rem; */
        }


    }



    @media (max-width:980px) {
        .videoContainer video {
            width: 20.5rem;
            height: 20.5rem;


        }

    }

    @media (981px <=width <=1400px) {
        .vid {
            width: 20rem;
            height: auto;
            border: 2px solid(255, 255, 255);
            margin-inline: 1rem;

        }
    }
}