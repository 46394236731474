.Pome1 {
    width: 90vw;
    height: auto;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    text-align: left;
}

.PomePotential {
    width: 25vw;
}

.PomePotential img {
    width: 100%;
    height: 18rem;
}

.PomePotential img:hover {
    scale: 1.1;
    transition: 0.5s linear;
    padding-bottom: 1rem;

}

.PomeWaste {
    width: 25vw;
}

.PomeWaste img {
    width: 100%;
    height: 18rem;
}

.PomeWaste img:hover {
    scale: 1.1;
    transition: 0.5s linear;
    padding-bottom: 1rem;

}

.PomeProblem {
    width: 25vw;
}

.PomeProblem img {
    width: 100%;
    height: 18rem;
}

.PomeProblem img:hover {
    scale: 1.1;
    transition: 0.5s linear;
    padding-bottom: 1rem;

}

.Pome2 {
    width: 90vw;
    height: auto;
    margin: 3rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Pome2 img {
    width: 100%;
    height: 30rem;
}

.Pome2 ul li {
    text-align: left;
    margin-top: 2rem;


}

.pome3 {
    width: 90vw;
    height: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 15rem;
    align-items: flex-start;
    text-align: left;

}

.PomeSource img {
    width: 100%;
    height: 21rem;
}

.PomeComposition img {
    width: 100%;
    height: 21rem;
}