.clientContainer {
    width: 90%;
    margin: 3% auto;
    height: auto;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;


}

.clientContainer img {
    width: 15rem;
    height: auto;
    align-items: center;

}

.clientContainer img:hover {
    border: 10px solid #f3f3f3;
}

.ClientHeading {

    color: var(--text-clr);
    text-shadow: var(--text-shadow);
    border-bottom: var(--border-bottom);
    width: 90%;
    text-transform: uppercase;
    margin: 0 auto;
    margin-top: 3rem;
    /* padding-top: 4rem; */
}

@media (max-width:600px) {
    .clientContainer img {
        width: 5rem;
        height: 5rem;
        align-items: center;

    }

    .clientContainer {
        width: 100vw;
        margin: 3% auto;
        height: auto;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-wrap: wrap;

        gap: 0.5rem;

    }

}