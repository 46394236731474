.Awards1 {
  width: 90%;
  margin: 3% auto;
  height: 23%;
  overflow: hidden;
  position: relative;





}



.Awards1 img {
  width: 30rem;
  height: 20rem;
  align-items: center;
  margin-inline: 0.5rem;
  /* padding-top: 2rem; */

  /* gap: 3rem; */

}

/* .awdimg5 {
  width: 30rem;
  height: 20rem;
}

.awdimg6 {
  width: 30rem;
  height: 20rem;
}

.awdimg7 {
  width: 30rem;
  height: 20rem;
}

.awdimg8 {
  width: 30rem;
  height: 20rem;
}

.awdimg9 {
  width: 30rem;
  height: 20rem;
}

.awdimg10 {
  width: 30rem;
  height: 20rem;
}

.awdimg11 {
  width: 30rem;
}

.awdimg12 {
  width: 30rem;
}

.awdimg13 {
  width: 30rem;
}

.awdimg14 {
  width: 30rem;
} */







/* .vid {
  width: 100%;
  height: auto;

} */

/* .swiper {
  width: 90%;




} */

/* .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 400px;
  height: 400px;
  padding: 0 20px;
  margin-top: 4rem;
} */

/* .swiper-slide img {
  display: block;
  width: 100%;
} */

/* .Awards1 {
  border-bottom: var(--border-bottom);
  color: var(--text-clr);
  text-shadow: var(--text-shadow);
  margin-left: -5rem;
  width: 90%;
  margin: 15px 50px;
}

.AwardName {
  text-align: center;
} */
@media (max-width: 600px) {

  .Awards1 img {
    width: 20rem;
    height: 14rem;
    align-items: center;
    margin-inline: 0.5rem;
    /* padding-top: 2rem; */

    /* gap: 3rem; */

  }


}