.fracbigImg{
  width: 100%;
  height: auto;
 margin: 0 auto;
}
/* .heroImage{
    width: 90%;
    height: 60vh;
    object-fit: cover;
    margin: 0;
} */
.refVidfrac{
  width: 30%
}