@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery-icon .image-gallery-right-nav {
    position: absolute;
    top: 50%;
    right: -50;
}

.image-gallery-slides {
    height: 100vh;
    width: 50vw;
    margin: 5rem auto;
    /* border: 5px solid white; */
    object-fit: fill;
    background-color: rgba(red, green, blue 0.5);
}


.image-gallery-thumbnails .image-gallery-thumbnails-container {
    height: 15rem;
}

.image-gallery-thumbnail {
    width: 10rem;
    /*  height: 15rem; */
}

/* .images {
    width: 10rem;
    height: 15rem;
} */


.image-gallery-slide .image-gallery-image {

    width: 50%;
    object-fit: contain;
    overflow: hidden;
}

.image-gallery-slide-wrapper {
    height: 40em;

    position: relative;
    object-fit: contain;
}

nav {


    background-color: rgb(134, 157, 177);
    border-radius: 10px;
    margin: 10px auto;
}