.card-img-top {
  width: 100%;
  height: 40vh;
}

.card {
  width: 50%;
  height: auto;
  box-shadow: 5px 5px #000;
  margin-left: 1rem;
}

.card-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: left;
}

.card-text {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: left;
}
