.wrapper {
  width: 100vw;
  height: 85vh;
  margin: 0 auto;
  position: relative;
}

.wrapper video {
  width: 100%;
  /*  height: 100%; */
}

.imageContainer {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.imageContainer > img {
  height: 100vh;
  width: 100vw;
  border-radius: 10px;
  object-fit: cover;
}

.caption {
  width: 35rem;
  height: inherit;
  font-size: 0.5rem;
  font-weight: 900;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: absolute;
  overflow: hidden;

  top: 0;
  right: 5%;
}

.caption-text {
  font-size: 1.3rem;
  color: #fff;
}

.txt {
  padding-top: 4rem;
  width: 100%;
}

.txt .p {
  width: 100%;
  padding: 2rem;
  text-align: justify;
}

.caption h1 {
  position: absolute;
  color: #fff;
  top: 0.5rem;
  color: aqua;
  padding-left: 35%;
}

@media (1600px <=width <=2600px) {
  .caption {
    width: 35rem;
    height: inherit;
    font-size: x-large;
    font-weight: 900;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    position: absolute;
    overflow: hidden;
    padding: 2rem;
    top: 0;
    right: 5%;
  }

  .txt {
    padding-top: 6rem;
    width: 100%;
  }

  .txt .p {
    width: 100%;
    padding: 2rem;
    text-align: justify;
  }

  .caption h1 {
    position: absolute;
    color: #fff;
    top: 1rem;
    color: aqua;
    padding-left: 35%;
    font-size: 3rem;
  }
}

@media (992px <=width <=1400px) {
  .wrapper {
    width: 100vw;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }

  .caption {
    width: 32rem;
    height: 38rem;
    font-size: 0.8rem;
    font-weight: 900;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    position: absolute;
    overflow: hidden;
    padding: 2rem;
    top: 0;
    right: 5%;
  }

  .txt p {
    text-wrap: wrap;
    font-size: 0.8rem;

    text-align: center;
    position: absolute;
  }

  .swiper {
    width: 100%;
    height: 50vh;
    overflow: hidden;
    /*  margin: -90 auto; */
  }

  .swiper-cube {
    max-width: 150%;
    height: 26rem;
  }

  .caption h1 {
    position: absolute;
    top: -0.3rem;
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    /*  right: 8rem; */
    color: red;
    text-align: center;
  }

  .caption p {
    position: absolute;
    color: #fff;
    top: 1rem;
    padding: 1rem;
  }

  /*  .wrapper {
        height: 26rem;
        overflow-x: hidden;
        position: relative;

    } */

  .txt {
    /*  padding-top: 4rem; */
    width: 100%;
  }
}

@media (768px <=width <=1100px) {
  .wrapper {
    height: auto;
    overflow-x: hidden;
    position: relative;
    background-color: #000;
  }

  .wrapper video {
    width: 100%;
    object-fit: fill;
    aspect-ratio: 13/10;
  }

  .imageContainer {
    overflow: hidden;
    position: absolute;
    width: 0;
    height: inherit;
    object-fit: cover;
  }

  .imageContainer > img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }

  .caption {
    width: 45%;
    height: 100%;
    right: 0.5rem;
    top: 0;
    padding: 0.5rem;

    position: absolute;
    text-wrap: wrap;
  }

  .txt {
    padding-top: 1.5rem;
  }

  .caption-text {
    font-size: 1rem;
  }

  ul .caption-textTop {
    font-size: 0.8rem;

    list-style: none;
  }

  .txt p {
    text-wrap: wrap;
    font-size: 0.8rem;
    padding-top: 0% !important;
    text-align: left;
    position: absolute;
  }

  .swiper {
    width: 100%;
    height: 70vh;
    overflow: hidden;
    /*  margin: -90 auto; */
  }

  .swiper-cube {
    max-width: 150%;
    height: 70vh;
  }

  .caption h1 {
    position: absolute;
    top: 0;
    font-weight: 900;
    color: #fff;
    font-size: 1.3rem;
    text-align: center;
    color: red;
  }

  .caption p {
    position: absolute;
    color: #fff;
    top: 1.5rem;
  }
}

@media (max-width: 600px) {
  .wrapper {
    height: auto;
    overflow-x: hidden;
    position: relative;
    background-color: #000;
  }

  .wrapper video {
    width: 100%;
    object-fit: fill;
    aspect-ratio: 9/11;
  }

  .imageContainer {
    overflow: hidden;
    position: absolute;
    width: 0;
    height: inherit;
    object-fit: cover;
  }

  .imageContainer > img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }

  .caption {
    width: 55%;
    height: 62vh;
    right: 0.5rem;
    top: 0;
    padding: 0.5rem;

    position: absolute;
    text-wrap: wrap;
  }

  .txt {
    padding-top: 1.5rem;
  }

  .caption-text {
    font-size: 0.7rem;
  }

  ul .caption-textTop {
    font-size: 0.8rem;

    list-style: none;
  }

  .txt p {
    text-wrap: wrap;
    font-size: 0.8rem;
    padding-top: 0% !important;
    text-align: left;
    position: absolute;
  }

  .swiper {
    width: 100%;
    height: 70vh;
    overflow: hidden;
    /*  margin: -90 auto; */
  }

  .swiper-cube {
    max-width: 150%;
    height: 70vh;
  }

  .caption h1 {
    position: absolute;
    top: 0;
    font-weight: 900;
    color: #fff;
    font-size: 1.3rem;
    text-align: center;
    color: red;
  }

  .caption p {
    position: absolute;
    color: #fff;
    top: 1.5rem;
  }
}
