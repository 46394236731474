.steampunk-3169877-1-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 85rem;
  height: 8.18rem;
  object-fit: cover;
}
.mecpro {
  margin-block-start: 0;
  margin-block-end: 20.19px;
}
.heavy-engineeing-limited {
  margin: 0;
  font-size: 2.41rem;
}
.mecpro-heavy-engineeing-container {
  position: absolute;
  top: 1.06rem;
  left: calc(50% - 549.97px);
  line-height: 84.52%;
  display: inline-block;
  width: 69.18rem;
  height: 6.57rem;
  text-shadow: 7.508227348327637px 9.009873390197754px 6.01px
    rgba(0, 0, 0, 0.37);
}
.untitled-3-2-icon {
  position: absolute;
  top: calc(50% - 56.56px);
  left: calc(50% - 44.88px);
  width: 7.06rem;
  height: 9.25rem;
  object-fit: cover;
}
.logo-place-holdervariant2 {
  position: absolute;
  top: 0.53rem;
  left: 1.74rem;
  background-color: var(--color-rosybrown);
  box-shadow: 0 0 8.22px 4.11px rgba(0, 0, 0, 0.36);
  width: 5.61rem;
  height: 7.07rem;
  overflow: hidden;
}
.steampunk-3169877-1-parent {
  position: absolute;
  top: 4.56rem;
  left: 0;
  width: 85rem;
  height: 8.18rem;
  font-size: 5.06rem;
  color: var(--color-white);
  font-family: var(--font-tiro-devanagari-sanskrit);
}
.menu-item1 {
  position: relative;
  letter-spacing: 0.1em;
  line-height: 144.02%;
}
.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.93rem 1.16rem;
  z-index: 6;
}
.menu-item3 {
  flex: 1;
  position: relative;
  letter-spacing: 0.1em;
  line-height: 144.02%;
}
.menu-item2,
.menu-item4,
.menu-item6,
.menu-item8 {
  display: flex;
  flex-direction: row;
  padding: 0.93rem 1.16rem;
}
.menu-item2 {
  width: 17.08rem;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  z-index: 5;
}
.menu-item4,
.menu-item6,
.menu-item8 {
  align-items: center;
  justify-content: center;
  z-index: 4;
}
.menu-item6,
.menu-item8 {
  z-index: 3;
}
.menu-item8 {
  z-index: 2;
}
.menu-item10 {
  padding: 0.93rem 1.16rem;
  z-index: 1;
}
.menu-item10,
.menu-item12,
.menu-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.menu-item12 {
  border-radius: 7.41px;
  background-color: var(--color-maroon-100);
  height: 2.66rem;
  padding: 0.93rem 1.16rem;
  box-sizing: border-box;
  z-index: 0;
  color: var(--color-white);
}
.menu-items {
  position: absolute;
  top: 0;
  left: calc(50% - 680px);
  background-color: #efefef;
  width: 85rem;
  height: 4.56rem;
  gap: 0.06rem;
  font-size: 1.2rem;
  font-family: var(--font-meera-inimai);
}
.sustainability-quality-managem-child {
  position: absolute;
  top: 14rem;
  left: calc(50% - 296px);
  border-radius: 20px;
  background-color: #2e0000;
  box-shadow: 0 4px 4px #fff inset, 0 4px 4px #fff inset, 0 4px 4px #fff,
    0 4px 4px rgba(255, 255, 255, 0.25);
  width: 34.13rem;
  height: 4.13rem;
}
.quality-management {
  position: absolute;
  top: 14.56rem;
  left: calc(50% - 244px);
  letter-spacing: 0.1em;
  line-height: 144.02%;
  text-transform: uppercase;
  display: inline-block;
  color: var(--color-white);
  width: 27.63rem;
  height: 2.81rem;
}
.all-work-carried {
  margin: 0;
}
.all-work-carried-container,
.quality-specifications {
  position: absolute;
  letter-spacing: 0.1em;
  line-height: 144.02%;
  text-transform: uppercase;
  display: inline-block;
}
.all-work-carried-container {
  top: 25rem;
  left: 12.31rem;
  font-size: var(--font-size-xl);
  width: 62.13rem;
  height: 1.75rem;
}
.quality-specifications {
  top: 34.75rem;
  left: 13.31rem;
  width: 30.31rem;
  height: 2.75rem;
}
.image-2-icon {
  position: absolute;
  top: 40.81rem;
  left: 10rem;
  width: 65rem;
  height: 31.31rem;
  object-fit: cover;
}
.our-quality-management {
  top: 78.19rem;
  left: calc(50% - 360px);
  width: 45rem;
  height: 3.38rem;
}
.inspection,
.our-quality-management,
.the-qc-facility {
  position: absolute;
  letter-spacing: 0.1em;
  line-height: 144.02%;
  text-transform: uppercase;
  display: inline-block;
}
.inspection {
  top: 135.56rem;
  left: 13.94rem;
  color: var(--bullets-points);
  width: 14.31rem;
  height: 2.75rem;
}
.the-qc-facility {
  top: 140.13rem;
  left: 10.25rem;
  font-size: var(--font-size-xl);
  width: 65rem;
  height: 14rem;
  text-emphasis-color: white;
}
p{
  color: #fff;
}
.visual-dimensional {
  margin-bottom: false;
}
.visual-dimensional-chemical {
  margin: 0;
  font-size: var(--font-size-xl);
  padding-left: 1.69rem;
}
.incoming-raw-material-container,
.painting {
  position: absolute;
  letter-spacing: 0.1em;
  line-height: 144.02%;
  text-transform: uppercase;
  display: inline-block;
}
.incoming-raw-material-container {
  top: 147.13rem;
  left: 10rem;
  font-family: var(--font-inherit);
  width: 65rem;
  height: 11.56rem;
  font-size: var(--font-size-6xl);
}
.painting {
  top: 156.5rem;
  left: 13.94rem;
  color: #a35fad;
  width: 11.13rem;
  height: 2.75rem;
}
.blank-line {
  margin: 0;
  font-size: var(--font-size-16xl);
}
.good-surface-preparation {
  font-size: var(--font-size-6xl);
}
.span {
  font-size: var(--font-size-16xl);
}
.good-surface-preparation-as-pe {
  margin: 0;
  padding-left: var(--padding-14xl);
}
.we-maintain-the-container {
  position: absolute;
  top: 160.38rem;
  left: 10rem;
  letter-spacing: 0.1em;
  line-height: 144.02%;
  text-transform: uppercase;
  display: inline-block;
  font-family: var(--font-inherit);
  white-space: pre-wrap;
  width: 65rem;
  height: 23.38rem;
  font-size: var(--font-size-6xl);
}
.image-3-icon {
  position: absolute;
  top: 82.87rem;
  left: calc(50% - 450px);
  width: 56.19rem;
  height: 46.13rem;
  object-fit: cover;
}
.packaging-shipment,
.text {
  position: absolute;
  letter-spacing: 0.1em;
  line-height: 144.02%;
  text-transform: uppercase;
  display: inline-block;
}
.packaging-shipment {
  top: 187.44rem;
  left: 13.94rem;
  color: var(--bullets-points);
  width: 28.81rem;
  height: 2.75rem;
}
.text {
  top: 274.5rem;
  left: 10rem;
  width: 65rem;
  height: 28.25rem;
}
.tdesignsystem-sum-icon,
.tdesignsystem-sum-icon1 {
  position: absolute;
  top: 27.94rem;
  left: 10.06rem;
  width: 1.13rem;
  height: 1rem;
  overflow: hidden;
}
.tdesignsystem-sum-icon1 {
  top: 25.38rem;
}
.our-packaging-are-container,
.quality-control,
.with-awareness-to {
  position: absolute;
  letter-spacing: 0.1em;
  line-height: 144.02%;
  text-transform: uppercase;
  display: inline-block;
}
.with-awareness-to {
  top: 27.63rem;
  left: 12.13rem;
  font-size: var(--font-size-xl);
  width: 61.88rem;
  height: 4.75rem;
}
.our-packaging-are-container,
.quality-control {
  top: 21.13rem;
  left: 13.31rem;
  width: 24.56rem;
  height: 2.75rem;
}
.our-packaging-are-container {
  top: 192.56rem;
  left: 10rem;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
  width: 65rem;
  height: 29.94rem;
}
.vector-icon,
.vector-icon1,
.vector-icon2,
.vector-icon3,
.vector-icon4 {
  position: absolute;
  height: 0.34%;
  width: 1.76%;
  top: 7.53%;
  right: 86.32%;
  bottom: 92.13%;
  left: 11.91%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon1,
.vector-icon2,
.vector-icon3,
.vector-icon4 {
  top: 12.1%;
  right: 86.47%;
  bottom: 87.56%;
  left: 11.76%;
}
.vector-icon2,
.vector-icon3,
.vector-icon4 {
  top: 46.33%;
  right: 86.18%;
  bottom: 53.33%;
  left: 12.06%;
}
.vector-icon3,
.vector-icon4 {
  top: 53.44%;
  bottom: 46.22%;
}
.vector-icon4 {
  top: 63.94%;
  right: 86.47%;
  bottom: 35.72%;
  left: 11.76%;
}
.sustainability-quality-managem {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 294.5rem;
  overflow-y: auto;
  text-align: left;
  font-size: var(--font-size-16xl);
  color: var(--color-black);
  font-family: var(--font-cabin);
}
