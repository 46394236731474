.MesoCarpImg {
  width: 100vw;
  height: 75vh;


}

.pomeimg {
  width: 35vw;
  height: 60vh;
  margin: 0;
}

@media (max-width: 600px) {
  .pomeimg {
    width: 90vw;
    height: auto;
    margin: 1rem;
  }

}