.mediaContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    gap: 1rem;



}