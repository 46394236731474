.icon,
.icon1 {
  position: absolute;
  top: 19.91rem;
  left: 11.34rem;
  width: 83.97rem;
  height: 46.91rem;
  object-fit: cover;
}
.icon1 {
  top: 83.19rem;
  width: 41.45rem;
  height: 18rem;
}
.hydrogenation-is-a {
  top: 85.32rem;
  left: 56.76rem;
  letter-spacing: 0.07em;
  line-height: 153.02%;
  text-align: left;
  width: 39.19rem;
  height: 18.71rem;
}
.a-process-in,
.hydrogenation-is-a,
.mecpro-has-in {
  position: absolute;
  display: inline-block;
}
.a-process-in {
  top: 110.33rem;
  left: 11.48rem;
  font-size: 2.48rem;
  letter-spacing: 0.1em;
  line-height: 144.02%;
  text-align: center;
  width: 83.83rem;
  height: 2.55rem;
}
.mecpro-has-in {
  top: 122.16rem;
  left: 11.34rem;
  font-size: 1.53rem;
  letter-spacing: 0.07em;
  line-height: 153.02%;
  width: 83.97rem;
  height: 20.69rem;
}
.icon2 {
  position: absolute;
  top: 132.51rem;
  left: 11.98rem;
  width: 83.4rem;
  height: 52.44rem;
  object-fit: cover;
}
.hydrogenation-child {
  position: absolute;
  top: 204.22rem;
  left: 11.69rem;
  background-color: #d9d9d9;
  width: 83.97rem;
  height: 34.3rem;
}
.b,
.the-use-of {
  position: absolute;
  left: 11.34rem;
  letter-spacing: 0.1em;
  line-height: 144.02%;
  display: inline-block;
  width: 84.32rem;
  height: 13.04rem;
}
.b {
  top: 187.5rem;
  font-size: 2.48rem;
}
.the-use-of {
  top: 244.75rem;
}
.the-advantages-of {
  margin: 0;
}
.process-carried-out {
  margin-bottom: false;
}
.process-carried-out-at-lower-t {
  margin: 0;
  padding-left: 1.34rem;
}
.the-advantages-of-container {
  position: absolute;
  top: 260.62rem;
  left: 11.34rem;
  font-size: inherit;
  letter-spacing: 0.1em;
  line-height: 144.02%;
  display: inline-block;
  font-family: inherit;
  width: 84.04rem;
  height: 22.68rem;
}
.untitled-1-40-icon,
.untitled-2-10-icon {
  position: absolute;
  top: 282.52rem;
  left: 11.34rem;
  width: 84.47rem;
  height: 44.57rem;
  object-fit: cover;
}
.untitled-1-40-icon {
  top: 325.53rem;
  height: 41.03rem;
}
.for-enquiry {
  position: absolute;
  top: 370.46rem;
  left: 2.62rem;
  font-size: 3.05rem;
  letter-spacing: 0.1em;
  line-height: 144.02%;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  width: 20.48rem;
  height: 4.61rem;
}
.hydrogenation {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 408.44rem;
  overflow: hidden;
  text-align: justify;
  font-size: 1.49rem;
  color: #000;
  font-family: Cabin;
}
