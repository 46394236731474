@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");


.dark-theme {
  --bg-theme-clr: linear-gradient(to right, #11012a, #510115, #682ec3, #11012a);
  --text-clr: #fff;
  --heading-clr: #fff;
  --text-shadow: 3px 1.6px #973636;
  --border-bottom: 2px solid yellow;
}

.light-theme {
  --bg-theme-clr: linear-gradient(to right, #fff, #fff);
  --text-clr: #333;
  --heading-clr: #333;
  --text-shadow: none;
  --border-bottom: 2px solid red;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  user-select: none;
}

html {
  height: 100%;
}

body {
  background-image: var(--bg-theme-clr);
  background-size: cover;
  object-fit: contain;
  background-repeat: repeat;
  margin: 0;
  padding-top: 100px;
  width: 100vw;
  color: var(--text-clr);
  height: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 800;
}
