.bakery-content {
    display: flex;
    padding: 3rem;
}

.bakeryImgBottom {
    width: 40vw;
    height: 70vh;
    padding-left: 5%;
}

.bakery-minIMG {
    width: 90vw;
    height: 30vh;
    margin: 0 auto;
    position: relative;
    left: 5%;

}

.Bakeryimg2 {
    width: 90vw;
    min-height: auto;
    position: relative;
    left: 5%;
}