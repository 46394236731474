.abtusHeading {
  color: #fff;
  text-shadow: var(--text-shadow);
  border-bottom: red;
  width: 90%;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 auto;
  margin-top: 3rem;
  padding-top: 10%;
}

.abtusHeading h1 {
  font-weight: 700;
  font-size: 5rem;
}

.aboutContainer {
  width: 100vw;
  height: auto;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 2% auto;
  padding: 50px;

  border-radius: 20px;
}

.aboutContent {
  width: 48%;
  height: 27rem;
  line-height: 1.9rem;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  overflow: visible;

  align-items: center;
}

.abtP {
  width: 100%;
  height: 100%;
  padding-top: 0.8rem;
  font-size: 1.3rem;
  font-weight: 700;
}

.aboutContent h1 {
  text-shadow: 3px 2px #973636;
  border-bottom: 1px solid yellow;
}

.aboutImages {
  width: 48%;
  height: 27rem;
  /*  padding-bottom: 2rem; */
}

.aboutImages video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding-bottom: 4rem;
}

.custom-bg {
  width: 100vw;
  height: 100vh;
}

.custom-bg img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.abtvid {

  border-radius: 20px;
}
.aboutImages {
  position: relative;
}

.video-container {
  position: relative;
  height: 100%;
}

.vid1 {
  width: 100%;
  height: 100%;
 
}
iframe{
  border-radius: 20px;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 30px;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.7;
}

.play-button:hover {
  opacity: 1;
}


@media (980px <=width <=1300px) {
  .aboutContent {
    height: 20rem;
    line-height: 1.3rem;
  }

  .abtP {
    font-size: 1rem;
  }
}

@media (1310px <=width <=1499px) {
  .aboutContent {
    height: 18.5rem;
    line-height: 1.3rem;
  }

  .abtP {
    font-size: 0.9rem;
  }
}

@media (1710px <=width <=2600px) {
  .abtusHeading {
    color: var(--text-clr);
    text-shadow: var(--text-shadow);
    border-bottom: red;
    width: 90%;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0 auto;
    margin-top: 3rem;
    padding-top: 10%;
  }

  .abtusHeading h1 {
    font-weight: 700;
    font-size: 4rem;
  }

  .aboutContainer {
    width: 100vw;
    height: auto;
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 2% auto;
    padding: 50px;

    border-radius: 20px;
  }

  .aboutContent {
    width: 48%;
    height: 31rem;

    padding: 0.5rem 0rem 0rem 0.5rem;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
  }

  .abtP {
    line-height: 2.2rem;
    height: 55vh;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }

  .aboutContent h1 {
    text-shadow: 3px 2px #973636;
    border-bottom: 1px solid yellow;
  }

  .aboutImages {
    width: 48%;
    height: auto;
    padding-bottom: 4rem;
  }

  .aboutImages video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding-bottom: 4rem;
  }
}

@media (972px <=width <=1400px) {
  .abtP {
    line-height: 1.5rem;
    height: 60vh;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }

  .aboutContent {
    width: 48%;
    height: 21.5rem;

    padding: 0.5rem 0rem 0rem 0.5rem;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
  }
}

@media (max-width: 600px) {
  .aboutContainer {
    padding-top: 10px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 2% auto;
    padding: 0;
    gap: 4.5rem;

    border-radius: 20px;
  }

  .aboutContent {
    width: 100vw;
    height: auto;
    line-height: 1.7rem;
    text-align: center;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }

  .aboutImages {
    width: 100vw;
    height: auto;
    margin-bottom: 18%;
  }

  .abtP {
    font-size: 1.2rem;
  }

  .abtusHeading h1 {
    font-weight: 700;
    font-size: 2rem;
  }
}

@media (max-width: 771px) {
  .aboutContainer {
    padding-top: 10px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 2% auto;
    padding: 0;
    gap: 4.5rem;

    border-radius: 20px;
  }

  .aboutContent {
    width: 90vw;
    margin: 0 auto;
    height: auto;
    line-height: 1.5rem;
    text-align: center;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }

  .aboutImages {
    width: 90vw;
    height: auto;
    margin-bottom: 18%;
  }

  .abtusHeading h1 {
    font-weight: 700;
    font-size: 2rem;
  }

  .abtP {
    font-size: 1rem;
  }
}
