.accordion {
  width: 80vw;
  height: auto;
  margin: 0 auto;

  border-radius: 0.8rem;
}

.accordion-item {
  font-size: 1.5rem;
  background-color: transparent;
  color: #ffffff;
  border: none;
}

.accordion-button {
  background-color: rgba(214, 91, 91);
  color: #fff;
  padding: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 0.8rem;
}

.faqHeading {
  text-align: center;
  font-size: 3rem;
  width: 90vw;
  margin: 3rem auto;
}

/* .accordion-body {
  background-color: #000000;
} */

@media (max-width: 600px) {
  .accordion {
    width: 90vw;
    margin: 0 auto;
  }

  .accordion-item {
    font-size: 1rem;
  }

  .accordion-button {
    background-color: rgba(214, 91, 91 0.1);
    color: #fff;
    padding: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
  }
}
