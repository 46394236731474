.contactHome{
    
}


@media (max-width: 600px) {
    .contactHome{
        display: block;
    }
    .contactHome .contactClient{
        display: none;
      }
}