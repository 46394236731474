.postContainer {
  width: 100vw;
  height: 100%;
  text-align: left;
  padding: 2rem;
  white-space: pre-wrap;
}

.postContainer h1 {
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.postContainer img {
  width: 100%;
  height: 50vh;
  margin: 0 auto;
}

.blogContent {
  width: 70%;
  height: auto;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 400;
  margin-top: 1rem;
  padding: 2rem;
  text-align: justify;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
}

aside {
  width: 30%;
  height: auto;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 400;
  margin-top: 1rem;
  padding: 2rem;
}

@media (max-width: 600px) {
  .blogContent {
    width: 100%;
    padding: 0rem;
  }
}
