.wax-is-usually {
  position: absolute;
  top: 84.85rem;
  left: 11.46rem;
  letter-spacing: -0.02em;
  line-height: 156.02%;
  display: inline-block;
  width: 42.18rem;
  height: 13.5rem;
}
.untitled-1-22-icon {
  position: absolute;
  top: 14.97rem;
  left: 46.82rem;
  width: 48.29rem;
  height: 34.31rem;
  object-fit: cover;
}
.bakery-shortening-or {
  position: absolute;
  top: 58.14rem;
  left: 46.68rem;
  letter-spacing: -0.02em;
  line-height: 156.02%;
  display: inline-block;
  width: 48.43rem;
  height: 23.76rem;
}
.untitled-1-23-icon {
  position: absolute;
  top: 58.84rem;
  left: 11.46rem;
  width: 32.06rem;
  height: 20.53rem;
  object-fit: cover;
}
.wax-purification1 {
  position: absolute;
  top: 27.28rem;
  left: calc(50% - 646.17px);
  font-size: 3.37rem;
  letter-spacing: 0.1em;
  line-height: 144.02%;
  display: inline-block;
  color: #0e03a9;
  text-align: center;
  width: 33.39rem;
}
.dsc00136-1-icon {
  position: absolute;
  top: 85.48rem;
  left: 61.3rem;
  width: 29.74rem;
  height: 16.24rem;
  object-fit: cover;
}
.wax-is-a {
  position: absolute;
  top: 108.89rem;
  left: 44.5rem;
  letter-spacing: -0.02em;
  line-height: 156.02%;
  display: inline-block;
  width: 43.58rem;
  height: 18.28rem;
}
.icon,
.icon1,
.icon2 {
  position: absolute;
  top: 106.29rem;
  left: 11.46rem;
  width: 16.52rem;
  height: 12.72rem;
  object-fit: cover;
}
.icon1,
.icon2 {
  top: 119.01rem;
  width: 14.06rem;
  height: 13.5rem;
}
.icon2 {
  top: 114.87rem;
  left: 27.35rem;
  width: 14.41rem;
  height: 13.43rem;
}
.rice-bran-oil {
  top: 137.22rem;
  color: #0e03a9;
}
.currently-the-rice,
.rice-bran-oil,
.rice-bran-wax {
  position: absolute;
  left: 11.46rem;
  letter-spacing: -0.02em;
  line-height: 156.02%;
}
.currently-the-rice {
  top: 140.95rem;
  display: inline-block;
  width: 73.04rem;
  height: 13.64rem;
}
.rice-bran-wax {
  top: 156.97rem;
}
.icon3,
.process-1-icon,
.process-2-1 {
  position: absolute;
  top: 159.57rem;
  left: 11.46rem;
  width: 83.79rem;
  height: 13.92rem;
  object-fit: cover;
}
.process-1-icon,
.process-2-1 {
  top: 176.16rem;
  width: 78.87rem;
  height: 50.4rem;
}
.process-2-1 {
  top: 230.93rem;
  left: 18.63rem;
  width: 64.6rem;
  height: 21.65rem;
}
.the-rice-bran {
  position: absolute;
  top: 255.67rem;
  left: 11.53rem;
  letter-spacing: -0.02em;
  line-height: 156.02%;
  display: inline-block;
  width: 73.32rem;
  height: 22.21rem;
}
.untitled-3-2-icon {
  position: absolute;
  top: calc(50% - 65.8px);
  left: calc(50% - 51.74px);
  width: 7.94rem;
  height: 10.4rem;
  object-fit: cover;
}
.logo-place-holdervariant2 {
  position: absolute;
  top: 273.69rem;
  left: 61.35rem;
  background-color: #d2aaaa;
  box-shadow: 0 0 9.24px 4.62px rgba(0, 0, 0, 0.36);
  width: 6.47rem;
  height: 8.23rem;
  overflow: hidden;
}
.for-enquiry {
  position: absolute;
  top: 283.93rem;
  left: 2.11rem;
  font-size: 3.02rem;
  letter-spacing: 0.1em;
  line-height: 144.02%;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  width: 18.28rem;
  height: 4.15rem;
}
.wax-purification {
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 277.88rem;
  overflow: hidden;
  text-align: justify;
  font-size: 1.41rem;
  color: #000;
  font-family: Cabin;
}
