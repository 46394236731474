/* Add this to your site's CSS */
.goog-te-menu-frame {
    max-width: 100% !important;
    box-sizing: border-box !important;
}

.goog-te-menu2 {
    max-width: 100% !important;
    overflow-x: auto !important;
    box-sizing: border-box !important;
    height: auto !important;
}

.goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div {
    width: auto !important;
    overflow: auto !important;
}
iframe.VIpgJd-ZVi9od-xl07Ob-OEVmcd {
    border: 1px solid #ccc; /* Example: Style the iframe border */
    overflow: auto !important;      /* Prevent unnecessary scrolling */
    max-width: 100%;       /* Responsive width */
}

/* Add these styles for the containing element of your Google Translate widget */
.google-translate-container {
    width: 100% !important;
    max-width: 100% !important;
    overflow: auto !important;
}
.css-qinwh{
    display: none;
}

/* Style the Google Translate iframe */
iframe.goog-te-menu-frame {
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    height: auto !important;
    width: 100% !important;
    max-height: 80vh !important;
    overflow: auto !important;
}

/* Additional mobile-specific styles */
@media screen and (max-width: 768px) {
    .goog-te-menu-frame {
        width: 100% !important;
        left: 0 !important;
    }
    
    .goog-te-menu2 {
        padding: 8px !important;
    }
    
    .goog-te-menu2-item div {
        padding: 8px !important;
    }
    
    .goog-te-menu2-colpad {
        display: none !important;
    }
    
    /* Improve touch targets on mobile */
    .goog-te-menu2-item {
        min-height: 40px !important;
        display: flex !important;
        align-items: center !important;
    }
}

