.cotlastimg {
  padding-bottom: 10px;
}

.Promp {
  font-size: 2.5rem;
  font-weight: 900;
  padding-left: 0;
  align-items: center;
  text-align: center;
  /* margin-bottom: 5rem; */
  padding-top: 10px;
  background: linear-gradient(145deg, yellow, rgb(244, 28, 28), #fff, rgb(255, 255, 255));
  -webkit-background-clip: text;
  background-size: 300% 300%;
  -webkit-text-fill-color: transparent;
  animation: gradient 10s ease-in-out infinite;
  background-color: rgba(0, 0, 0, 0.4);
}

.layerh5 {
  height: 27rem;
  line-height: 2em;
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.5);
}