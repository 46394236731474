.postBlog {
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    border: 1px solid #fff;
    padding: 1rem 0.5rem;
    border-radius: 1rem;
    box-shadow: 5px 5px 10px 10px rgba(214, 91, 91);


}

label {
    width: 20%;
    margin: 1rem auto;
    font-size: 1.5rem;
}

.blogtitle {
    width: 80%;
    padding: 1rem 1rem;
    border-radius: 1rem;
    background-color: transparent;

}

.imageUrl {
    width: 80%;
    padding: 1rem 1rem;
    border-color: #fff;
    border-radius: 1rem;
    background-color: transparent;
}

.blogDescription {
    width: 80%;
    border-radius: 1rem;

}

.submitButton {
    background-color: rgba(214, 91, 91);
    color: #fff;
    width: 20%;
    padding: 1rem 1rem;
    border-radius: 1rem;
    margin-top: 1rem;

}