nav {
  width: 100vw;
  height: 5rem;
  padding: 0;
  color: #fff;
}

.navbar-nav {
  margin: 0 auto;
}

.gap-4 {
  align-items: center;
}

.form-check-label {
  color: #000;
  font-size: 1.5rem;
  font-weight: 500;
}
/* #custom-switch::after {
  content: "Theme";
} */
.download {
  color: #000;
  margin-top: 1rem;
}
.brochure {
  background-color: rgba(214, 91, 91);
  border-radius: 20px;
  color: #fff;
  padding: 0.5rem;
  margin-left: 0.5rem;
  font-size: 1.5rem;
}

a.nav-link:hover {
  background-color: rgba(214, 91, 91);
  border-radius: 20px;
  color: rgb(255, 255, 255);
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

@media (max-width< 600px) {
  nav {
    width: 100vw;
  }

  .nav {
    width: 100vw;
    height: 3rem;
    background-color: #fff;
  }

  .navbar-nav {
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    width: 100vw;
    margin: 0 2rem 0 0;
    color: #fff;
    position: absolute;
    left: 0;
  }

  .nav-link {
    color: #000;
  }

  .dropdown-menu {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }

  .dropdown-item {
    color: #fff;
  }

  /*    .dropdown,
    .dropend {
        background-color: rgba(0, 0, 0, 0.5);
    } */
  .download{
    margin-top: 0rem;
  }
  .active {
    /*  background-color: rgba(214, 91, 91); */
    color: #fff;
  }
}

@media (980px <=width <=1400px) {
  .nav {
    width: 100vw;
    height: 3rem;
    background-color: rgba(0, 0, 0, 0.5);
    border: 5px solid red;
  }

  .nav-link {
    color: #000;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0;
  }

  .navbar-nav {
    /*  background-color: rgba(0, 0, 0, 0.8); */
    /*   backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px); */
    width: 100vw;
    margin: 0 auto;
    gap: 1rem;
    position: absolute;
    left: 2.9rem;
    color: black;
  }

  .navbar-nav a {
    color: #000;
  }

  .active {
    /*  background-color: rgba(214, 91, 91); */
    color: #fff;
  }

  .navbar-light {
    background-color: black;
  }
}
