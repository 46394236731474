.workVid video {
  width: 40%;
  height: auto;
  /* margin-right: 50rem; */
  /* object-fit: contain; */
  background-color: #000;

  box-shadow: 15px 15px 10px rgb(13, 13, 13);
  border: 10px solid #000000;
  border-radius: 20px;

}

.workVid {
  width: 95vw;
  height: 50vh;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.imgwraper {
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;

}

.imgwraper img {
  width: 14rem;
  height: auto;
}