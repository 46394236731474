.scontainer{
     
       width: 100%;
       height: auto;
       margin: 8rem  auto 0 auto;
      padding-bottom: 10px;
      display: block;
      

      
      

}
.serviceContainer{
   display: flex;
   flex-direction: column;
    
   width: 100%;
   height: 100%;
justify-content: center;
align-items: center;
gap: 2rem;

   
}
.serviceContainer img{
    padding-top: 3rem;
    width: 45rem;
    height: auto;
}


#service1{
    width: 75rem;
}
.serviceHeading{
    color: var(--text-clr); 
    text-shadow: var(--text-shadow);
    border-bottom: var(--border-bottom);
    width: 90%; 
    height: auto;
    margin: 3rem auto; 
   
   
}
.background{
    width: 100%;
    height: 100%;
}

@media (max-width: 600px){
    #service1{
        width: 20rem;
    }
    #service2{
        width: 20rem;
    }
    .scontainer{
     
        width: 100%;
        height: 50vh;
        margin: 5rem auto;
       padding-bottom: 10px;
       display: block;
       
 
       
       
 
 }


}


    