.values {
  display: flex;
  width: 90vw;
  margin: 0 auto;
  gap: 10rem;
}

.value {
  width: 50%;


}

.refImge {
  width: 40.75rem;
  height: 27rem;
  border: 2px solid rgba(214, 91, 91);
  box-shadow: -10px -10px 5px rgba(214, 91, 91);
  z-index: 10;

}

.value2 {
  display: flex;
  gap: 2rem;

}

.refVideo {
  width: auto;
  height: 50px;
}

.ref1h5 {
  margin: 0, auto;
}

@media (max-width:600px) {

  
  
}