.containerHead {
  margin-top: 0;
  /*  background-image: linear-gradient( #180830 10%, #38022A 60%);*/
  width: 100%;
  padding-left: 4%;
}

.links {
  list-style: none;
  color: #180830;
  text-decoration: none;
  font-size: 1.5rem;
}

.topNav {
  display: flex;
  justify-content: space-evenly;
  padding-top: 1rem;

  color: #fff;
}

ul li {
  list-style: none;
}

.ContactUS {
  border-radius: 5px;
  width: 7rem;
  height: 2rem;
  align-items: center;
}

.headerTop {
  display: flex;
  width: 100%;
  height: 8rem;
  color: #f5f5f5;
  margin: 0;
}

.siteHeading {
  padding-top: 0.2rem;
  font-weight: bold;
}

.Logo {
  width: 100px;
  height: 104px;

  margin-right: 20px;
}

.siteHeading1 {
  font-size: 4rem;
  padding-left: 1rem;
  margin-bottom: -1rem;
  text-shadow: 7px 7px 20px #000;
  background: linear-gradient(
    145deg,
    rgb(244, 28, 28),
    yellow,
    #fff,
    rgb(190, 49, 49),
    rgb(124, 1, 1)
  );
  -webkit-background-clip: text;
  background-size: 300% 300%;
  text-align: left;
  font-weight: bold;
  -webkit-text-fill-color: transparent;
  animation: gradient 5s ease-in-out;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  75% {
    background-position: 80% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.siteHeading2 {
  padding-left: 1rem;
  padding-top: 0.5rem;
  /*   background: url('./images/universal.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: transparent;
    -webkit-background-clip: text; */
  background: linear-gradient(
    145deg,
    rgb(244, 28, 28),
    rgb(190, 49, 49),
    rgb(124, 1, 1)
  );
  -webkit-background-clip: text;
  background-size: 300% 300%;
  text-shadow: 7px 7px 30px #000;

  font-weight: 900;
  -webkit-text-fill-color: transparent;
  animation: gradient 5s ease-in-out;
}
a {
  text-decoration: none;
}

@media (972px <=width <=1400px) {
  .siteHeading1 {
    font-size: 3rem;
    padding-left: 0.2rem;
    margin-bottom: -1rem;
  }

  .Logo {
    width: 65px;
    height: 80px;
    /* margin-right: 20px; */
  }

  .headerTop {
    display: flex;
    width: 100%;
    height: 6rem;
    color: #f5f5f5;
    margin: 0;
  }

  .siteHeading2 {
    padding-left: 0.2rem;
  }
}

@media (max-width: 600px) {
  .siteHeading1 {
    font-size: 2rem;
    padding-left: 0.2rem;
    margin-bottom: -1rem;
    text-shadow: 7px 7px 20px #000;
    background: linear-gradient(
      145deg,
      rgb(244, 28, 28),
      yellow,
      #fff,
      rgb(190, 49, 49),
      rgb(124, 1, 1)
    );
    -webkit-background-clip: text;
    background-size: 300% 300%;
    text-align: left;
    font-weight: bold;
    -webkit-text-fill-color: transparent;
    animation: gradient 5s ease-in-out;
  }

  .siteHeading2 {
    padding-left: 0.2rem;
    font-size: 1rem;
    padding-top: 12px;
    background: linear-gradient(
      135deg,
      rgb(243, 152, 152),
      yellow,
      skyblue,
      rgb(26, 26, 244),
      rgba(214, 91, 91, 0.654),
      rgb(220, 7, 43),
      purple
    );
    -webkit-background-clip: text;
    background-size: 300% 300%;
    text-shadow: 7px 7px 20px #000;
    font-weight: bold;
    -webkit-text-fill-color: transparent;
    animation: gradient 10s ease-in-out infinite;
  }

  .Logo {
    width: 50px;
    height: 65px;
    /* margin-right: 20px; */
  }
}
