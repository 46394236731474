.projectContainer {
  width: 100vw;
  height: auto;

  /* background-image: linear-gradient( #180830 10%, #3801.61.6A 60%);*/

  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto;

  overflow: hidden;
}

.projectTop {
  width: 100vw;
  height: 50%;
  background-color: rgb(169, 214, 91);
  position: absolute;
  color: #fff;
  top: 0;
  z-index: 11;
  overflow: hidden;
  border-bottom: 5px solid #fff;
}

.projectTop h1 {
  font-weight: 900;
  font-size: 200px;
  transform: translate(0%, 130%);
  text-transform: uppercase;
}

.projectBottom {
  width: 100vw;
  height: 50%;
  background-color: #11012a;
  z-index: 10;
  position: absolute;
  padding-top: 10%;
  bottom: 0%;

  overflow: hidden;
}

.projectBottom h1 {
  font-weight: 900;
  font-size: 200px;
  transform: translate(0%, -110%);
  text-transform: uppercase;
  color: #fff;
}

/* .projectTop {
  width: 100vw;
  height: 50vh;
  background-color: rgb(169, 214, 91);
  position: absolute;
  padding-bottom: 100px;
  top: 0;
  z-index: 11;
  overflow: hidden;
  border-bottom: 5px solid #fff;
}

.projectBottom {
  width: 100vw;
  height: 50vh;
  background-color: #11012a;
  z-index: 10;
  position: absolute;
  padding-top: 10%;
  bottom: 0;

  overflow: hidden;
} */

.projectHeading {
  width: 90%;
  height: auto;
  border-bottom: var(--border-bottom);
  color: var(--text-clr);
  text-shadow: var(--text-shadow);
  /* text-align: left; */
  text-transform: uppercase;
  /* padding-top: 25%; */
  position: relative;
  /* left: 1rem; */
}

.projectImg {
  width: 14rem;
  height: 14rem;
  background-color: rgba(214, 91, 91);

  box-shadow: 10px 10px 10px rgba(214, 91, 91),
    -10px -10px 10px rgba(214, 91, 91);
  border-radius: 20px;
}

.img1 {
  margin: 0 auto;
  width: 14rem;
  height: 14rem;
  border-radius: 20px;
}

.projectCard {
  transition: 0.6s;
  position: relative;
}

.projectImg :hover {
  transform: perspective(900px) rotatex(25deg) translateZ(40px);
  transition: 1s linear;
}

.projectImg ::after :hover {
  translate: 0 30px;
  filter: blur(1rem);
}

.title {
  color: rgb(96, 2, 11);
  font-weight: 1000;
  text-align: center;
  text-wrap: wrap;
  position: absolute;
  bottom: 10px;
  padding: 5px 10px;
  background-color: #ecdead;
  width: 100%;
}

.projectHeading {
  width: 100%;
  height: auto;
  border-bottom: var(--border-bottom);
  color: var(--text-clr);
  text-shadow: var(--text-shadow);
  text-align: center;
  text-transform: uppercase;

  position: relative;
  left: 1rem;
}

@media (1600px <=width <=2600px) {
  .projectContainer {
    width: 100vw;
    height: auto;

    /* background-image: linear-gradient( #180830 10%, #3801.61.6A 60%);*/

    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto;

    overflow: hidden;
  }

  .projectTop {
    width: 100vw;
    height: 50%;
    background-color: rgb(169, 214, 91);
    position: absolute;
    color: #fff;
    top: 0;
    z-index: 11;
    overflow: hidden;
    border-bottom: 5px solid #fff;
  }

  .projectTop h1 {
    font-weight: 900;
    font-size: 200px;
    transform: translate(0%, 126%);
    text-transform: uppercase;
  }

  .projectBottom {
    width: 100vw;
    height: 50%;
    background-color: #11012a;
    z-index: 10;
    position: absolute;
    padding-top: 10%;
    bottom: 0%;

    overflow: hidden;
  }

  .projectBottom h1 {
    font-weight: 900;
    font-size: 200px;
    transform: translate(0%, -121%);
    text-transform: uppercase;
    color: #fff;
  }
  .img1 {
    width: 14rem;
    height: 14rem;
  }

  .projectImg {
    width: 14rem;
    height: 14rem;
  }
}

@media (972px <=width <=1400px) {
  .img1 {
    width: 11rem;
    height: 11rem;
  }

  .projectImg {
    width: 11rem;
    height: 11rem;
  }

  .projectContainer {
    gap: 2.5rem;
  }
}

@media (max-width: 768px) {
  .projectContainer {
    width: 100vw;
    height: auto;
    margin-top: 1rem;
    gap: 3rem;
  }

  .projectImg {
    width: 11rem;
    height: 11rem;
  }

  .img1 {
    width: 11rem;
    height: 11rem;
  }

  .projects {
    width: 11rem;
    height: 11rem;
  }

  .projectTop,
  .projectBottom {
    display: none;
  }
}

@media (max-width: 600px) {
  .projectContainer {
    width: 100vw;
    height: auto;
    margin-top: 2rem;
    gap: 1rem;
  }

  .projectImg {
    width: 9.5rem;
    height: 9.5rem;
  }

  .img1 {
    width: 9.5rem;
    height: 9.5rem;
  }

  .projects {
    width: 9.5rem;
    height: 9.5rem;
  }

  .projectTop,
  .projectBottom {
    display: none;
  }
}
