.solmidimg {
  width: 100%;
}

.refinerysol {
  padding-top: 10px;
}

.refinery1 h5 {
  height: auto;
  line-height: 2em;
  text-align: justify;
  display: flex;
  /* align-items: center; */
  padding: 1em;
  font-size: 1.4rem;

  background-color: rgba(0, 0, 0, 0.6);
}
