.pomeimg {
  width: 45%;
  margin: 0;
}

.pomeLast {
  width: 80%;
  height: auto;
  margin: 3em auto;
}

.pomeLastImg {
  width: 100%;
}

.pome-text-right {
  width: 80%;
  margin: 1em auto;
  text-align: right;

}

.faqContainer {
  display: flex;

}

.faqImages {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

}

.faqImages img {
  width: 90%;
  height: 20%;
  margin: 0 auto;
}

.new {
  width: 100%;
}

.pome {
  width: 100%;
}

@media (max-width:600px) {

  .faqImages {
    display: none;
  }

}