.contact-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: url("../components/images/contactbg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% 30%;
  width: 100vw;
  height: 100vh;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 2vw 4vw;
  width: 40%;
  height: 76vh;
  border: 1px solid #fff;
  /*  margin: 2.5rem auto; */
  /*  border: 2px solid #fff; */
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: (10px);
  /*   border-radius: 1rem;
 */
}

.name {
  display: flex;
  gap: 1rem;
}

.contact-input {
  background: none;
  border: none;
  border-bottom: 2px solid #fff;
  margin: 0.8rem auto;
  padding: 5px;
  width: 25rem;
  height: 2rem;
}

.company {
  background: none;
  border: none;
  border-bottom: 2px solid #fff;
  margin: 0.8rem auto;
  padding: 0.5rem;
  width: 30rem;
  height: 2rem;
}

.contactDetails {
  display: flex;
  gap: 2rem;
}

.contact-input-phone {
  background: none;
  border: none;
  border-bottom: 2px solid #fff;
  margin: 0.8rem auto;
  padding: 0.5rem;
  width: 14rem;
  height: 2rem;
}

.contact-input-email {
  background: none;
  border: none;
  border-bottom: 2px solid #fff;
  margin: 0.8rem auto;
  padding: 0.5rem;
  width: 14rem;
  height: 2rem;
}

.select {
  display: flex;
  gap: 2rem;
}

.Countries {
  background: none;
  border: none;
  border-bottom: 2px solid #fff;
  margin: 0.8rem auto;
  padding: 0.5rem;
  width: 14rem;
  height: 2rem;
}

.Category {
  background: none;
  border: none;
  border-bottom: 2px solid #fff;
  margin: 0.8rem auto;
  padding: 0.5rem;
  width: 14rem;
  height: 2rem;
}

input[type="text"] {
  color: #fff;
}

input::placeholder {
  color: #fff;
}

textarea::placeholder {
  color: #fff;
}

select {
  color: #fff;
}

textarea {
  width: 30rem;
  margin: 2rem auto;
  padding: 1rem;
  color: #fff;
  background: none;
  border: none;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  box-shadow: -2px -2px 10px #fff;
}

.forenq {
  width: 50%;
  margin: 3rem auto;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 2rem;
}

.contact-gender {
  width: 4rem;
  padding: 0.5rem;
  height: 2rem;
  margin: 0.8rem auto;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 2px solid #fff;
}

.contact-gender {
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 2px solid #fff;
}

.contact-submit {
  padding: 0.4rem;
  border-radius: 1rem;
  width: 10rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 800;
  cursor: pointer;
  background-color: rgba(214, 91, 91);
  margin-left: 20rem;
}

option {
  background-color: rgba(0, 0, 0, 0.8);
}

.contactimg {
  display: flex;
  padding: 1rem;
  width: 27vw;
  height: 76vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: (10px);
}

.contactimg img {
  width: 100%;
  height: 100%;
}

.address {
  width: 27vw;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: (10px);
  height: 76vh;
  padding: 1.5rem;
}

.contactLinksRight {
  width: 100%;
  height: 40vh;
}

.contactLinksRight h2 {
  color: rgba(214, 91, 91);
  font-weight: 800;
}

.contactOverseasAddress {
  width: 100%;
  height: 40vh;
}

.contactOverseasAddress h2 {
  color: rgba(214, 91, 91);
  font-weight: 800;
}

@media (max-width: 600px) {
  .contact-wrapper {
    width: 95vw;
    height: auto;
    margin: 0 auto;
    flex-direction: column;
    background-image: url("../components/images/contactbg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 30% 30%;
    overflow: hidden;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: left;

    flex-wrap: wrap;
    padding: 2rem;
    width: 90vw;
    height: auto;
    margin: 1rem auto;
    border: 1px solid #fff;

    background-color: rgba(255, 255, 255, 00.1);
    border-radius: 0.2rem;
  }

  .contactimg {
    display: none;
  }

  .company {
    margin-right: 16rem;
    width: 90%;
  }

  .contact-input {
    border: 0;
    margin-right: 15rem;
    width: 90%;
    padding: 10px;
    background: none;
    border: none;
    border-bottom: 2px solid #fff;
  }

  .name {
    flex-direction: column;
    width: 100%;
    margin-right: 13rem;
    gap: 0rem;
  }

  .contact-input-phone {
    background: none;
    border: none;
    border-bottom: 2px solid #fff;
    margin: 0.2 auto;
    padding: 0.5rem;
    width: 90%;
    height: 2rem;
  }

  .contact-input-email {
    background: none;
    border: none;
    border-bottom: 2px solid #fff;
    margin: 0.2 auto;
    padding: 0.5rem;
    width: 90%;
    height: 2rem;
  }

  .Countries {
    background: none;
    border: none;
    border-bottom: 2px solid #fff;
    margin: 0.2 auto;
    padding: 0.5rem;
    width: 90%;
    height: 2rem;
  }

  .Category {
    background: none;
    border: none;
    border-bottom: 2px solid #fff;
    margin: 0.2 auto;
    padding: 0.5rem;
    width: 90%;
    height: 2rem;
  }

  .contact-gender {
    width: 20vw;
    margin-right: 15rem;
  }

  .contactDetails {
    flex-direction: column;
    margin-right: 15rem;
    width: 100%;
    gap: 0rem;
  }

  .select {
    flex-direction: column;
    width: 100%;
    margin-right: 15rem;
    gap: 0rem;
  }

  textarea {
    width: 90%;
    background: none;
    border: none;
    height: 14vh;
    margin-right: 16rem;
    border-bottom: 2px solid #fff;
  }

  .forenq {
    width: 100%;
    margin: 3rem auto;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    border-radius: 2rem;
  }

  .address {
    width: 100vw;
    height: auto;
  }

  .contact-submit {
    margin-left: 0;
    width: 50%;
    padding: 0.5rem;
    margin-right: 5rem;
    overflow: hidden;
  }
}
