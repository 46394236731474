.spentImages{
  width: 80%;

  margin: 3em auto;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.spentImages>img{
  width:100%;
}


.spentImages2{
  width: 100%;

  display: flex;
  margin:0 auto;
}
.spentImages2>img{
  width: 33%;
  height:29rem;
}
.spentLast{
  width: 80%;
  margin: 3em auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.spentLast>img{
  width: 30%;
}